* {
  box-sizing: border-box;
}

h1, .like-h1 {
  font-size: 2rem;
}

.like-h1 {
  display: block;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h2 {
  font-size: 2rem;
  margin: 0.5rem 0;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.75rem;
  font-weight: normal;
}

h5 {
  font-size: 1.5rem;
  font-weight: normal;
}

h6 {
  font-size: 1.25rem;
  font-weight: normal;
}

p {
  margin: 0;
  font-size: 1rem;
  font-weight: 100;
  line-height: 1.5rem;
}

.global span {
  font-size: 1rem;
  font-weight: 100;
  line-height: 1.5rem;
}

.global div {
  line-height: 1.5rem;
}

.global tr {
  font-size: 1rem;
  font-weight: 100;
  line-height: 1.5rem;
}

.global td {
  font-size: 1rem;
  font-weight: 100;
  line-height: 1.5rem;
}

.takeover_left {
  left: calc( -120px - 2rem);
  position: absolute;
}

.takeover_right {
  right: calc( -120px - 2rem);
  position: absolute;
}

.flagsvg {
  box-sizing: unset;
  min-width: 1rem;
  min-height: 1rem;
}

@media (max-width: 480px) {
  .logo-teams-header p {
    display: none;
  }
  .logo-teams-header img, .td-stats-t2-logo img, .td-stats-t1-logo img {
    width: 100%;
  }
  .td-stats-t2-logo img, .td-stats-t1-logo img, .competition-name, .award {
    display: none;
  }
  .tips-div {
    text-align: center;
  }
  .top-banner .ad728x90 {
    display: none !important;
  }
  .Footer {
    margin: 0;
    min-height: 8.5rem;
    align-items: flex-start;
    padding: 1.5rem 1rem 0;
  }
  .ad320x50 {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    margin-bottom: -1px;
  }
  .scrollUp {
    display: none;
  }
  html {
    font-size: 12px;
  }
  .competition-name-h2h {
    display: none;
  }
}

@media (min-width: 480px) {
  html {
    font-size: 13px;
  }
  .ad320x50 {
    display: none;
  }
}

@media (max-width: 520px) {
  .competition-flag-h2h img {
    width: 20px !important;
    justify-content: center;
  }
}

@media (min-width: 992px) {
  .top-banner .ad728x90 {
    display: none !important;
  }
  html {
    font-size: 14px;
  }
}

@media (max-width: 992px) {
  .takeover .ad120x600 {
    display: none !important;
  }
  html {
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1921px) {
  html {
    font-size: 18px;
  }
  .takeover {
    width: 50% !important;
  }
}