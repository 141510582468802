.App {
  text-align: left;
  background-color: #dcdcdc;
}

.adsbygoogle {
  display: block;
}

.header {
  background-image: url('./css/imgs-bg/banner.webp');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  align-items: left;
  color: #fff;
  background-color: #303488;
  padding: 1.5rem 0 1.5rem 0;
  margin: 0 0 1.5rem;
}

.side-space {
  padding-left: 15%;
  padding-right: 15%;
}

@media (max-width: 360px) {
  .liveText {
    font-size: 0.5rem;
  }
  .body-form {
    flex-wrap: wrap;
  }
  .bar-stats {
    display: none;
  }
  .liveText-mobile {
    display: none;
  }
}

@media (max-width: 480px) {
  h1, .like-h1 {
    font-size: 1rem;
  }
  .side-space {
    padding: 1rem;
    margin-bottom: 1rem;
  }
  .Header .header-container {
    width: 100%;
  }
  .table-body img {
    width: 20px;
    height: auto;
  }
  .switch-stats {
    width: 15px;
    height: 15px;
    margin: auto;
  }
  .competition-flag-icon {
    width: 10%;
  }
  .clock {
    width: 15%;
  }
  .team.home {
    width: 25%;
  }
  .result {
    width: 15%;
  }
  .team.away {
    width: 25%;
  }
  .stats-mobile {
    width: 10%
  }
  .livetext-mobile {
    width: 10%
  }
  .logo {
    max-width: 5rem;
    margin-bottom: 0;
  }
  .footer-p {
    padding-top: 0;
  }
  .logo-teams-header {
    width: 25%;
    margin: 0;
  }
  .td-stats-data {
    width: 82%;
    margin: 0;
  }
  .table-h2h {
    padding: 0.5rem;
  }
  .table-stats {
    padding: 0;
  }
  .tips-values-home {
    width: 100%;
  }
  .tips-values-away {
    width: 100%;
  }
  .tips-div {
    padding: 1.5rem;
    margin: 1rem;
    background-image: none;
    background-color: #1D1C21;
    color: white;
  }
  .scrollUp-div {
    display: none;
  }
  .competition-name-h2h {
    display: none;
  }
  .h2id {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .tips-div {
    background-color: #DD0014;
  }
}

@media (min-width: 480px) {
  .side-space {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media (min-width: 768px) {
  .Header .header-container {
    width: 75%;
  }
  .tips-div-home {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .side-space {
    padding-left: 15%;
    padding-right: 15%;
  }
  .main {
    min-height: 561px;
  }
}

@media (max-width: 992px) {
  .scrollUp-div {
    display: none;
  }
}

@media (min-width: 1200px) {
  .Header .header-container {
    width: 50%;
  }
}

@media (min-width: 1921px) {
  .side-space {
    padding-left: 25%;
    padding-right: 25%;
  }
}