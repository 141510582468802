.Footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #303488;
  color: white;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  font-weight: 100;
  margin-top: 5rem;
  height: 5rem;
}

button {
  border: solid white;
  color: #303488;
  font-family: 'Roboto', sans-serif;
}

button.selected {
  background-color: #303488;
  color: white;
}