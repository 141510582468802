Body {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: 1vw;
  color: #949695;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  margin: 0 auto;
}

/* ad */

.takeover {
  position: relative;
  width: 70%;
  margin: 0 auto;
}

.takeover_left {
  padding-top: 2.5rem;
}

.takeover_right {
  padding-top: 2.5rem;
}

.ad468x60 {
  width: 100%;
  justify-content: center;
  display: flex;
}

.ad728x90 {
  width: 100%;
  justify-content: center;
  display: flex;
  padding-bottom: 2rem;
}

.svg.logo-stats:hover {
  fill: red;
}

.switch-stats {
  background-image: url("./imgs-bg/statistics.png");
  width: 2rem;
  height: 1rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.gameRow {
  display: contents;
  text-decoration: none;
  color: #949695;
}

.gameRow tr {
  border-bottom: 1px solid #303488;
}

.gameRow:hover {
  color: #303488;
}

.gameRow:hover .switch-stats {
  background-image: url("./imgs-bg/statistics-blue.png");
}

.gamestr:hover {
  background-color: #8080801f;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  color: #949695;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  margin: 0 auto;
}

.table-header {
  background-color: #303488;
  color: white;
}

.table-header th {
  font-size: 1.5em;
  height: 2.5rem;
  padding: 0 1em;
  margin: 0;
}

.table-header img {
  max-width: 15px;
  height: auto;
}

.logo {
  max-width: 7rem;
  height: auto;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.table-body {
  background-color: white;
}

.table-body img {
  width: 25px;
  height: auto;
}

.table-body .stats img {
  width: 20px;
  height: 20px;
}

.table-body td {
  height: auto;
  padding: 0.5rem 0;
  align-items: center;
  border-bottom: 1px solid #949695;
}

.iconflag {
  cursor: pointer;
}

.competition-flag {
  width: 5%;
}

.clockorder {
  cursor: pointer;
}

.clock {
  font-size: 11px;
  width: 5%;
}

.competition-name {
  font-size: 12px;
  font-weight: bold;
}

.team {
  font-size: 12px;
  font-weight: bold;
}

.homeorder {
  cursor: pointer;
  text-align: right;
}

.home-live {
  font-size: 12px;
  text-align: right;
  width: 15%;
  color: red;
  font-weight: bold;
}

.awayorder {
  cursor: pointer;
  text-align: left;
}

.away-live {
  font-size: 12px;
  text-align: left;
  width: 15%;
  color: red;
  font-weight: bold;
}

.team.home {
  text-align: right;
  width: 15%;
}

.team.away {
  text-align: left;
  width: 15%;
}

.result {
  font-size: 0.7rem;
  width: 2rem;
  width: 10%;
}

.result .ft {
  font-size: 1.2em;
}

.live {
  font-weight: bold;
  color: red;
}

.live-flash {
  font-weight: bold;
  font-size: 1.2em;
  color: red;
  -webkit-animation: flash linear 2s infinite;
  animation: flash linear 2s infinite;
}

.live-color {
  background-color: rgba(96, 96, 95, 0.20);
}

.result-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.streaming {
  font-size: 11px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.text-streaming {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.channel a {
  text-decoration: none;
}

.channel b {
  text-decoration: none;
  padding-right: 0.5em;
}

.streaming .channel {
  display: flex;
  align-items: center;
}

.text-streaming .channel:last-child .text-channel b {
  display: none;
}

.streaming .channel img {
  margin-right: 0.1em;
}

.table-header span {
  font-size: 1rem;
  font-weight: 100;
}

.table {
  display: table;
  width: 100%;
  padding-bottom: 2rem;
  text-align: center;
}

.text-channel {
  text-decoration: none;
  color: #949695;
}

.channel a .text-channel {
  color: #303488;
  font-weight: bold;
}

.channel a:hover {
  text-decoration: underline;
  text-decoration-style: dotted;
}

@media screen and (max-width: 480px) {
  .streaming .channel img {
    margin-right: 0;
  }
  .streaming .channel .text-channel {
    display: none;
  }
  .streaming {
    display: none;
  }
}

.button-calendar {
  color: white;
  background-color: #303488;
  float: right;
  border-width: thin;
  display: flex;
  width: 14rem;
  cursor: pointer;
}

.button-calendar p {
  margin: 0 0.5em;
}

.dropdown {
  color: rgba(96, 96, 95, 0.05);
  position: relative;
  height: 2.5rem;
}

.button-calendar img {
  float: left;
}

.dropdown button {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  border: none;
  padding: 0 1rem;
}

.winner {
  background-color: red;
}

.dropdown button.button-left.disable img {
  display: none;
}

.dropdown button.button-left.disable {
  border-color: transparent;
  background-color: transparent;
}

.dropdown button.button-right.disable img {
  display: none;
}

.dropdown button.button-right.disable {
  border-color: transparent;
  background-color: transparent;
}

.dropdown button.button-left {
  width: 3rem;
}

.dropdown button.button-right {
  width: 3rem;
}

.dropdown button.option {
  width: 100%;
  background-color: white;
  border: none;
}

.dropdown button.option:hover {
  width: 100%;
  background-color: #303488;
  color: white;
}

.dropdown button.option.selected {
  width: 100%;
  background-color: #303488;
}

.dropdown button.option p {
  margin: 0.5em;
}

.menu {
  bottom: 0;
  transform: translate(0, 100%);
  position: absolute;
  -webkit-box-shadow: -2px 0px 10px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 0px 10px -3px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 0px 10px -3px rgba(0, 0, 0, 0.75);
  right: 3rem;
  width: 14rem;
}

.menu button {
  display: block;
  cursor: pointer;
}

.button-left {
  width: 36px;
  height: 31px;
  background-color: white;
  float: right;
  border-width: thin;
  cursor: pointer;
}

.button-right {
  width: 1em;
  height: 31px;
  background-color: white;
  float: right;
  border-width: thin;
  cursor: pointer;
}

ul {
  list-style-type: none;
  padding-right: 2.5rem;
}

.trstats {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0;
}

.stats-title {
  background-color: #303488;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
}

.team-name {
  line-height: 0;
  padding-right: 0.5rem;
}

.stat-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
  margin-bottom: 1rem;
  padding: 0.5rem;
}

.stat-area .text-note {
  font-size: 0.75rem;
  line-height: 1.1em;
  margin-top: 0.75em;
}
.stat-area .text-note a {
  color: inherit;
}

.match-head-score {
  text-align: center;
  margin: 0;
  padding: 0;
}

.teamsHeader h1 {
  font-size: initial;
  font-weight: normal;
  margin: 0;
}

.logo-teams-header p {
  color: #303488;
  font-weight: bold;
  padding-top: 0.5rem;
}

.logo-teams-header {
  width: 100%;
  margin: 0;
}

.td-stats-data {
  width: 100%;
  margin: 0;
}

.f-score-odd {
  margin: 0;
  padding: 0;
  display: block;
  color: #303488;
  font-size: 2rem;
  font-weight: bold;
  line-height: 25px;
}

.td-stats {
  width: 250px;
  height: 250px;
}

.liveText {
  font-weight: 500;
  color: red;
  font-size: 1rem;
  -webkit-animation: flash linear 2s infinite;
  animation: flash linear 2s infinite;
}

@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .1;
  }
  100% {
    opacity: 1;
  }
}

/* Pulse class and keyframe animation */

.pulseit {
  -webkit-animation: pulse linear .5s infinite;
  animation: pulse linear .5s infinite;
}

@-webkit-keyframes pulse {
  0% {
    width: 200px;
  }
  50% {
    width: 340px;
  }
  100% {
    width: 200px;
  }
}

@keyframes pulse {
  0% {
    width: 200px;
  }
  50% {
    width: 340px;
  }
  100% {
    width: 200px;
  }
}

.gamestatus-live {
  text-align: center;
  padding: 5px;
  margin: 0px 2px 10px 2px;
  display: block;
  color: red;
  font-size: 12px;
  font-weight: bold;
}

.gamestatus {
  text-align: center;
  margin: 0;
  padding: 0;
  display: block;
  color: #303488;
  font-size: 12px;
  font-weight: bold;
}

.game_date b {
  color: #303488;
}

.competition-stat-name {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-weight: 400;
}

.competition-stat-name img {
  width: 30px;
  padding-right: 0.5rem;
}

.season-name {
  font-weight: 400;
}

.round-name {
  font-weight: 400;
}

.stats-buttons li {
  text-align: center;
  background-color: #303488;
  color: white;
  text-align: center;
  padding: 0.3em;
  text-transform: uppercase;
  font-size: 1em;
  align-items: center;
  width: 70em;
  height: 2em;
}

.stats-buttons li:hover {
  background-color: white;
  color: #303488;
}

.stats-buttons li.selected {
  background-color: white;
  color: #303488;
}

.title-summary {
  background-color: #303488;
  padding: 0.5rem;
  color: white;
  align-items: center;
  display: flex;
}

.title-h2h {
  background-color: #303488;
  color: white;
  padding: 0.5rem;
  margin: 0;
  cursor: pointer;
}

.title-h2h span {
  color: white;
  font-size: 1rem;
}

.title-tips {
  background-color: #303488;
  color: white;
  padding: 0.5rem;
  margin: 0;
}

.title-tips p {
  color: white;
  font-size: 1rem;
}

.title-form {
  background-color: #303488;
  color: white;
  padding: 0.5rem;
  margin: 0;
  cursor: pointer;
}

.title-form p {
  color: white;
  font-size: 1rem;
}

.title-team {
  background-color: #303488;
  padding: 0.5rem;
  margin: 0;
  cursor: pointer;
}

.title-team p {
  color: white;
  font-size: 1rem;
}

.title-stats {
  background-color: #303488;
  color: white;
  padding: 0.5rem;
  margin: 0;
  cursor: pointer;
}

.title-stats p {
  color: white;
  font-size: 1rem;
}

.rotate-icon {
  transform: rotate(180deg);
}

.f-score-extratime {
  display: block;
  color: #303488;
  font-size: 1rem;
  font-weight: 500;
}

.f-score-penalty {
  display: block;
  color: #303488;
  font-size: 1rem;
  font-weight: 500;
}

.global .title-form, .global .title-h2h, .global .title-team, .global .title-stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.global .title-form p, .global .title-h2h p, .global .title-summary p, .global .title-team p, .global .title-stats p {
  margin: 0;
}

.is-loading {
  min-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ball {
  padding-right: 0.5rem;
}

.icons {
  display: flex;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #303488;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}